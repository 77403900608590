import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface TypeBackground {
		error?: string;
	}
}

const theme = createTheme({
	palette: {
		primary: { main: '#f2d45c' },
		background: {default: '#121212ad'},
		error: {main: '#640202'},
		mode: 'dark'
	}
});

export default theme;
