import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import ReactTimeago from "react-timeago";
import { SERVICE_URL } from "../config";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { maxWidth, width } from "@mui/system";

export type Advertisement = {
    ads: Ad[]
};

export type Ad = {
    _id: string;

}

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }

type AdData = {
    _id: string,
    name: string,
    text: string,
    url: string,
    information?: string,
    active: boolean,
    order?: number
}

function sortAds(r: AdData[]): AdData[] {
  return r.sort((a: AdData, b: AdData) => {
    a.order = a?.order ?? 0;
    b.order = b?.order ?? 0;
    if (a.order < b.order) {
      return 1;
    }
    if (a.order > b.order) {
      return -1;
    }
    return 0; 
    }, );
}

const TabPanel:FC<TabPanelProps> = ({children, dir, index, value, ...other}) => {

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

export const AdvertisementWidget: FC = () => {

    const [adData, setAdData] = useState<AdData[]>([]);
    const [time, setTime] = useState(new Date());
    const [loading, setLoading] = useState<Boolean>(true);
    const [activeTab, setActiveTab] = useState<number>(0);
    
    useEffect(() => {
      var timerID = setInterval(() => tick(), 1*3600*1000);
      var timerCarousel = setInterval(() => tickCarousel(), 100*1000);
      return () => {clearInterval(timerID); clearInterval(timerCarousel)};
    });

    useEffect(() => {
        fetch(SERVICE_URL + "/ads")
        .then(res => res.json())
        .then(result => sortAds(result.results.filter((r: AdData) => r.active)))
        .then(res => setAdData(res as AdData[]))
        .then(_ => setLoading(false))
        .catch(e => console.log(e));
    }, [time])

    const tick = () => {
        setTime(new Date());
    };

    const tickCarousel = () => {
        setActiveTab(prev=>(prev+1)%adData.length);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
      };

    console.log(adData);
    return(
        !loading ? (
          adData.length < 0 ? <p>"Nothing to advertise."</p> : (
        <>
            <Tabs value={activeTab} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="basic tabs">
                {adData.map((ad, i) => <Tab key={i} label={ad.name} />)}
            </Tabs>
            <Box>
                {adData.map((ad, i) => 
                    <TabPanel index={activeTab} value={i}>
                      <img style={{maxWidth: "100%"}} src={ad.url} alt={ad.name} />
                      <p>{ad.text}</p>
                      <Button variant="contained" href={"https://"+ad.information}>{ad.information}</Button>
                    </TabPanel>
                )}
            </Box>
            <Typography variant="caption" sx={{color: '#ffffff59'}}>
                Last update:&nbsp;
                <ReactTimeago date={time} minPeriod={5}></ReactTimeago>
            </Typography>
        </>)
        ) : <LinearProgress />
    )
}
