import { FC, useEffect, useMemo, useState } from "react";
import { EventRecord } from "../Components/EventRecord";
import ReactTimeago from "react-timeago";
import Typography from "@mui/material/Typography";
import LinearProgress from '@mui/material/LinearProgress';
import { SERVICE_URL } from "../config";

export interface BoardEvent extends BoardEventBaseComponent {
    type: 'VEVENT';
    method: any;
    dtstamp: Date;
    uid: string;
    sequence: string;
    transparency: any;
    class: any;
    summary: string;
    start: Date;
    datetype: any;
    end: Date;
    location: string;
    description: string;
    url: string;
    completion: string;
    created: Date;
    lastmodified: Date;
    rrule?: any;
    attendee?: any;
    /* eslint-disable-next-line @typescript-eslint/ban-types */
    recurrences?: Record<string, Omit<BoardEvent, 'recurrences'>>;
  
    // I am not entirely sure about these, leave them as any for now..
    organizer: any;
    exdate: any;
    geo: any;
    recurrenceid: any;
  }
  
  export interface BoardEventBaseComponent {
    params: any[];
  }

export const UpcomingEvents: FC = () => {

    const emtpy_events = new Map<string, BoardEvent>();
    const [events, setEvents] = useState<Map<string, BoardEvent>>(emtpy_events);
    const [updated, setUpdated] = useState<Date>(new Date());
    const [loading, setLoading] = useState<Boolean>(true);

    const sortEvents = () => {
        let a = events.entries();
        return Array.from(a)
        .sort((obj1, obj2) => new Date(obj1[1].start).getTime()-new Date(obj2[1].start).getTime())
        .filter(obj => new Date(obj[1].end) > new Date());
    }

    const sortedEvents = useMemo(() => sortEvents(), [events]);

    const loadData = () => {
        var startToday = new Date();
        startToday.setUTCHours(0,0,0,0);
        var endToday = new Date();
        endToday.setUTCHours(23,59,59,999);

        fetch(SERVICE_URL + "/events/recent")
        .then(res => res.json())
        .then(result => {
            result.results.forEach((event: BoardEvent) => {
                if (events.has(event.uid)){
                    return;
                }
                setEvents((prev: Map<string, BoardEvent>): Map<string, BoardEvent> => {
                    return new Map(prev.set(event.uid, event));
                })
            })
            setLoading(false);
        })
        .catch(error => console.log(error));
        setUpdated(new Date());
    }

    useEffect(() => {
        loadData();
        const interval = setInterval(()=>{loadData()},900000)
        return ()=> clearInterval(interval)
    }, []);

    return(
        !loading ? (
            <>
            {sortedEvents.length === 0 ? (<p>There are no events planned for this day.</p>) : (
                sortedEvents.map((v, i) => {
                    return <EventRecord event={v[1]} />
                })
            )}
            <Typography variant="caption" sx={{color: '#ffffff59'}}>
                Last update:&nbsp;
                <ReactTimeago date={updated} minPeriod={5}></ReactTimeago>
            </Typography>
        </> ) : <LinearProgress />
    )
}