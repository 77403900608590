import { Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import ReactTimeago from "react-timeago";
import { WEATHER_WIDGET_UPDATE_PERIOD } from "../config";
import Ansi from "ansi-to-react";

export const WeatherWidget: FC = () => {

    const [time, setTime] = useState(new Date());
    const [weather, setWeather] = useState(<Ansi></Ansi>);

    useEffect(() => {
      var timerID = setInterval(() => tick(), WEATHER_WIDGET_UPDATE_PERIOD*3600*1000);
      return () => clearInterval(timerID);
    });

    useEffect(() => {
        const weather_source = `https://wttr.in/Brno?0mA`;
        fetch(weather_source)
        .then(res => res.text())
        .then(result => {
            setWeather(
            <Ansi>
                {result}
            </Ansi>
            );
        }).catch(e => console.log(e));
    }, [time])

    function tick() {
        setTime(new Date());
    };

    return(
        <div style={{whiteSpace: "pre-wrap", fontSize: "medium", lineHeight: 1.0}}>
        {weather}
        <br/>
        <Typography variant="caption" sx={{color: '#ffffff59'}}>
            Last update:&nbsp;
            <ReactTimeago date={time} minPeriod={5}></ReactTimeago>
        </Typography>
        </div>
        );
}
