import { LocalCafe } from "@mui/icons-material";
import { FC } from "react";

export const TableCountBadge: FC = ({children}) => {

    let position = "";
    switch(children){
        case 1:
            position = "first"
            break;
        case 2:
            position = "second"
            break;
        case 3:
            position = "third"
            break;
        default:
            position = ""
            break;
    }
    const classes = `featured-table-cell-badge ${position}`;
    return(
        <span className={classes}>
            {position ? <LocalCafe className="coffee-icon"/> : children}</span>
    )
}