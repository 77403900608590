import { LocalCafe } from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import ReactTimeago from "react-timeago";

type Props = {
	user_name: string;
	time: number;
};

export const RecentRecord: FC<Props> = ({user_name, time}) => {
    let date = new Date(time);

    return (
        <>
        <Paper
        variant="elevation"
        sx={{
            display: 'flex',
			justifyContent: 'space-between',
            flexWrap: 'wrap',
            flexDirection: 'column',
            px:1.5,
            py:2,
            mt:0,
            mb:1
        }}>
            <Typography variant="caption" sx={{
                mt: '-10px',
                mb: '-3px',
                textAlign: 'right',
                color: '#ffffff59'
            }}>
                <ReactTimeago date={date} className="text-muted"/>
            </Typography>
            <p style={{margin:0}}>
                <LocalCafe className="coffee-icon"/>
                {user_name}
            </p>
        </Paper>
        </>
    )
}