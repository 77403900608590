import { RecordsRecent } from "../App";

export const filterNonEmptyUsers = (stats: any) => {
    return [...stats.keys()].filter(function(v){
        if (stats.get(v)) {
            return (stats.get(v) || {count: 0}).count > 0;
        }
    });
}

export const limitRecentRecords = (records: RecordsRecent[]) => {
    const max = 8;
    if (records.length > max) {
        return records.splice(0, max);
    }
    return records;
}