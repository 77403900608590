import { FC, useEffect, useState } from "react";
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import {RecordsRecent, SocketEvent} from "../App";
import {Observable} from "rxjs";
import { SERVICE_URL } from "../config";
import { RecentRecord } from "../Components/RecentRecord";
import { limitRecentRecords } from "../Util/filters";
import LinearProgress from '@mui/material/LinearProgress';

type Props = {
    usersObserver: Observable<SocketEvent>,
    recordObserver: Observable<SocketEvent>
};

export const RecentItems:FC<Props> = ({usersObserver, recordObserver}) => {

    let defaultRecords: RecordsRecent[] = [];
    const [recentRecords, setRecentRecords] = useState<RecordsRecent[]>(defaultRecords);
    const [loading, setLoading] = useState<Boolean>(true);

    useEffect(() => {
        fetch(SERVICE_URL + "/records/recent")
        .then(res => res.json())
        .then(result => {
            let records = Array.from<RecordsRecent>(result.results);
            records = records.sort((r1: RecordsRecent, r2: RecordsRecent) => r2.time - r1.time);
            setRecentRecords(limitRecentRecords(records));
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        const sub = recordObserver.subscribe(row => {
            switch(row.data.operationType){
                case 'insert': {
                    const tmp:RecordsRecent = {
                        time: row.data.createdAt, 
                        name: row.data.name,
                        id: row.data._id,
                        issuer: row.data.issuer,
                    }
                    setRecentRecords(prev => {
                        return [tmp,...prev.slice(0, -1)]
                    });
                    break;
                }
                default: console.log("Unsupported operation type on 'record observer'"); 
            }
          });
        return () => sub.unsubscribe();
    }, [recordObserver]);

    useEffect(() => {
        const sub = usersObserver.subscribe(row => {
            switch(row.data.operationType){
                case 'update': {
                    // Only if name is updated
                    if (!('name' in row.data.updatedFields)){return}
                    setRecentRecords(prevState => {
                        const a = prevState.map(rec => {
                            return rec.issuer === row.data.documentKey._id ? {...rec, name: row.data.updatedFields.name} : rec;
                        })
                        return a;
                    });
                    break;
                }
                default: console.log(row.data)
            }
        });
        return () => sub.unsubscribe()
    }, [usersObserver]);
    
    return (
        !loading ? (

            <TransitionGroup>
            {recentRecords.map((record: RecordsRecent) => (
                <CSSTransition
                key={record?.id}
                timeout={500}
                classNames={'slide-down'}
                in>
                    <RecentRecord time={record?.time} user_name={record.name || "Anonymous"} />
                </CSSTransition>
            ))}
        </TransitionGroup> ) : <LinearProgress />
        ) 
}
