import { FC } from "react"
import { Paper, Typography } from "@mui/material"

type Props = {
    title?: string;
    type?: 'static' | 'dynamic';
}

export const Widget: FC<Props> = ({title, type, children}) => {
    return (
        <Paper
        variant="elevation"
            sx={{
                px:2,
                py:2,
                mb:1,
                backgroundColor: '#000000d6', // type==='static' ? '#000000d6' : 'background.default',
                // backdropFilter: 'blur(5px)'
            }}
        >
            {title ? <Typography variant="h4" sx={{mb:2}}>{title}</Typography> : ""}
            {children}
        </Paper>
    )
}