import { FC, useEffect, useState } from "react";
import { Typography } from "@mui/material";


export const Clock: FC = () => {

    const [time, setTime] = useState(new Date());


    useEffect(() => {
      var timerID = setInterval(() => tick(), 1000);
   
      return () => clearInterval(timerID);
    });

    function tick() {
        setTime(new Date());
    };

    return(
        <>
            <Typography variant='h2' align='center' sx={{fontWeight: 'bold'}}>
                {time.getHours().toString().padStart(2,'0')}:{time.getMinutes().toString().padStart(2,'0')}:{time.getSeconds().toString().padStart(2,'0')}
            </Typography>
            <Typography variant='h6' align='center'>
                {time.toLocaleDateString("cs-CZ")}
            </Typography>
        </>
    )
}