import { FC, useEffect, useState } from "react";
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import {RecordsRecent, SocketEvent} from "../App";
import {Observable} from "rxjs";
import LinearProgress from '@mui/material/LinearProgress';
import { Box, Card, CardActions, CardContent, CardMedia, Divider, Grid, Paper, Typography } from "@mui/material";
import cappybarra from '../assets/capybarra.jpg'
import QRCode from "react-qr-code";
import LinearProgressWithLabel from "../Components/LinearProgressWithLabel";

type Props = {
    authObserver: Observable<SocketEvent>,
};

const normalise = (current:number, total: number) => (current / total * 100);

export const AuthenticationWidget:FC<Props> = ({authObserver}) => {

    const [hasActiveAuthRequest, setHasActiveAuthRequest] = useState<Boolean>(false);
    const [loginInfo, setLoginInfo] = useState<any>({})
    const [progress, setProgress] = useState<number>(0)

    useEffect(() => {
        const pbar = setInterval(() => {
            setProgress((prevProgress: number) => (prevProgress + 1));
            if (progress >= 180) {
              clearInterval(pbar);
              setHasActiveAuthRequest(false)
            }
          }, 1000);
          return () => {
            clearInterval(pbar);
            setHasActiveAuthRequest(false)
          };
    }, []);

    useEffect(() => {
        const sub = authObserver.subscribe(row => {
            console.log(row)
            switch(row.data.operationType){
                case 'insert': {
                    setHasActiveAuthRequest(true)
                    setProgress(0)
                    setLoginInfo(row.data)
                    break;
                }   
                case 'status': {
                    setHasActiveAuthRequest(false)
                    break;
                }
                case 'message': {
                    setLoginInfo(row.data)
                    break;
                }
                default: console.log("Unsupported operation type on 'record observer'"); 
            }
          });
        return () => sub.unsubscribe();
    }, [authObserver]);
    
    return (
        hasActiveAuthRequest ? (
            <><Box sx={{position: "absolute", top: 0, width: "100%", height: "100%", background: "#000000ad", left: 0}}></Box>
            <Card sx={{ width: 500, height: "98.5%", position: "absolute", top: "10px", bottom: "10px" }}>
                <CardMedia
                    sx={{ height: 500, backgroundSize: "contain" }}
                    image={cappybarra}
                    title="e-infra cz logo"
                />
                <CardContent>
                    <Typography gutterBottom variant="h4" component="div">
                    Welcome {loginInfo?.task?.name},
                    </Typography>
                    <Typography sx={{ fontSize: "1.25em" }} color="text.secondary" gutterBottom>
                        Looks like you have a new card or you haven't linked your e-INFRA CZ identity yet. You may use the following code to pair your card with your account.
                    </Typography>
                    <Paper variant="outlined" sx={{p: 2}}>
                        <Typography variant="h5" color="text.primary" sx={{textAlign: "center"}}>
                            Your pairing code
                        </Typography>
                        <Typography variant="h5" color="text.primary" sx={{textAlign: "center", mt: 2, mb: 2}}>
                            <b>{loginInfo.user_code || "XYZF"}</b>
                        </Typography>
                        <Typography variant="h5" color="text.primary" sx={{textAlign: "center"}}>
                            And use this link:
                        </Typography>
                        <Typography variant="h6" color="text.primary" sx={{textAlign: "center", mt: 2, mb: 2}}>
                            <b>{loginInfo.verification_uri || "https://login.e-infra.cz/auth/"}</b>
                        </Typography>
                        <Divider orientation="horizontal" about="or" sx={{mb: 2}} />
                        <Typography variant="h5" color="text.primary" sx={{textAlign: "center", mb: 2}}>
                            Or scan this QR code:
                        </Typography>
                        <Box sx={{display: "flex", justifyContent: "center"}}>
                            <QRCode size={256}
                                    style={{ height: "auto", maxWidth: "50%", width: "50%", padding: "10px", background: "#FFF"}}
                                    viewBox={`0 0 256 256`} 
                                    value={loginInfo.verification_uri_complete || "https://cesnet.cz"}  
                                    />
                        </Box>
                    </Paper>
                    <LinearProgressWithLabel variant="determinate" value={normalise(progress, 180)} />
                    <CardActions sx={{justifyContent: "center"}}>
                    </CardActions>
                    </CardContent>
                </Card>
            </>
        ) : <></>
        ) 
}
