import { TableCell, TableRow } from "@mui/material";
import { FC } from "react";
import { TableRecord } from "../Widgets/HighScoreWidget";
import FlipMove from 'react-flip-move';
import { TableCountBadge } from "./TableCountBadge";
import { TableRecordOrganization } from "./TableRecordOrganization";

type Props = {
    random: Number,
    stats: Map<string, TableRecord>
}
export const TableRecords: FC<Props> = ({stats}) => {
    return (
        <>
        <FlipMove typeName={null}>
            {Array.from(stats.entries()).map((v, i) => 
                <TableRow key={v[0]}>
                    <TableCell padding="checkbox" align="center">
                        <TableCountBadge>{++i}</TableCountBadge>
                    </TableCell>
                    <TableCell padding="normal">
                        {v[1]?.name}
                    </TableCell>
                    <TableCell align="left" padding="normal">
                        {v[1]?.total}
                    </TableCell>
                    <TableCell align="left" padding="none">
                        <TableRecordOrganization>{v[1]?.organization}</TableRecordOrganization>
                    </TableCell>
                </TableRow>
            )}
        </FlipMove>
        </>
    )
}