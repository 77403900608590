import {FC, useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {SocketEvent} from "../App";
import {Observable} from "rxjs";
import { SERVICE_URL } from "../config";
import { TableRecords } from "../Components/TableRecords";
import LinearProgress from '@mui/material/LinearProgress';

function sortRank(rank: any): Map<string, TableRecord> {
    return new Map([...rank].sort(([k, v]: [string, TableRecord], [k2, v2]: [string, TableRecord]) => {
        if (v.total < v2.total) {
          return 1;
        }
        if (v.total > v2.total) {
          return -1;
        }
        return 0; 
      }));
}
export interface TableRecord {
    _id: string,
    name: string,
    organization: string,
    total: number
}

type Props = { 
    usersObserver: Observable<SocketEvent>, 
    recordObserver: Observable<SocketEvent>
};

export const HighScore:FC<Props> = ({usersObserver, recordObserver}) => {

    const [rank, setRank] = useState<Map<string, TableRecord>>(new Map<string, TableRecord>());
    const [random, setRandom] = useState<Number>(0);
    const [loading, setLoading] = useState<Boolean>(true);

    useEffect(() => {
        fetch(SERVICE_URL + "/stats")
        .then(res => res.json())
        .then(result => {
            let records = Array.from<TableRecord>(result.results);
            records.forEach((user: TableRecord) => {
                setRank(prevState => {
                    prevState.set(user._id, user);
                    return sortRank(prevState);
                });
            })
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        const sub = recordObserver.subscribe(row => {
            switch(row.data.operationType){
                case 'insert': {
                    setRank(prevState => {
                        let current = prevState.get(row.data.issuer);
                        if (!current) {
                            current = {
                                _id: row.data.issuer,
                                total: 0,
                                name: row.data.name,
                                organization: "E Corp"
                            }
                        };
                        current.total++;
                        prevState.set(row.data.issuer, current);
                        return sortRank(prevState);
                    });
                    break;
                }
                default: console.log(row.data) 
            }
            });
        return () => sub.unsubscribe()
    }, [recordObserver]);

    useEffect(() => {
        const sub = usersObserver.subscribe(row => {
            switch(row.data.operationType){
                case 'update': {
                    setRank(prevState => {
                        const current = prevState.get(row.data.documentKey._id);
                        if (!current) {
                            return prevState;
                        }
                        prevState.set(current._id, {...current, ...row.data.updatedFields});
                        return prevState;
                    });
                    setRandom(Math.random);
                    break;
                }
                case 'insert': {
                    setRank(prevState => {
                        console.log("New user to HighScore", row.data);
                        if (!row.data?.__v) {return prevState;}
                        prevState.set(row.data._id, {...row.data, total: 0});
                        return prevState;
                    });
                    setRandom(Math.random);
                    break;
                }
                default: console.log(row.data)
            }
        });
        return () => sub.unsubscribe()
    }, [usersObserver]);

    return (
        !loading ? (
        <Table>
            <TableHead sx={{
                backgroundColor: 'background.paper'
            }}>
                <TableRow>
                    <TableCell align="center">#</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Total</TableCell>
                    <TableCell align="left">Org.</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRecords stats={rank} random={random} />
            </TableBody>
        </Table>) : <LinearProgress />
    )
}