import { FC } from "react";
import muni_white from "../assets/flags/muni_white.png"
import cesnet_rgb from "../assets/flags/cesnet_white.png";

export const TableRecordOrganization: FC = ({children}) => {

    let image = "";
    switch(children){
        case "Masarykova univerzita":
        case "Masaryk University":
        case "MUNI ICS":
            image = muni_white
            break;
        case "CESNET":
        case "CESNET, z. s. p. o.":
            image = cesnet_rgb
            break;
        default:
            image = ""
            break;
    }
    // const classes = `featured-table-cell-badge ${position}`;
    const classes = ``;
    return(
        <span className={classes}>
        {/* //     {position ? <LocalCafe className="coffee-icon"/> : children} */}
        {image ? (<img src={image} style={{width:"80px"}} alt="Logo" />) : ""}</span>
    )
}