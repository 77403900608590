import { Event } from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
import { FC } from "react";
import { BoardEvent } from "../Widgets/UpcomingEvents";
import { EventRecordDate } from "./EventRecordDate";

type Props = {
	event: BoardEvent;
};

export const EventRecord: FC<Props> = ({event}) => {

    return (
        <>
        <Paper
        key={event.uid}
        variant="elevation"
        sx={{
            display: 'flex',
			justifyContent: 'space-between',
            flexWrap: 'wrap',
            flexDirection: 'column',
            px:1.5,
            py:2,
            mt:0,
            mb:1
        }}>
            <p style={{margin:0}}>
                <Event sx={{mr: '0.5rem', verticalAlign:'middle'}}/>
                <span style={{fontWeight: 'bold'}}>{event.summary}</span>
                <p style={{margin:'2px 0px'}}>
                    <EventRecordDate start={new Date(event.start)} end={new Date(event.end)} />
                </p>
                <Typography variant="caption" sx={{
                mt: '1px',
                mb: '-3px',
                textAlign: 'left',
                color: '#ffffff59',
                display: 'block',
                float:'left'
                }}>
                    {event.location}
                </Typography>
            </p>
        </Paper>
        </>
    )
}