import React, {useState} from 'react';
import {HighScore} from './Widgets/HighScoreWidget';
import {CssBaseline, Grid} from '@mui/material';
import {Observable} from "rxjs";
import socketIOClient from "socket.io-client";
import {SOCKET_URL} from './config';
import {Clock} from './Widgets/ClockWidget';
import {ThemeProvider} from '@mui/system';
import theme from './theme';
import {RecentItems} from './Widgets/RecentCoffeeWidget';
import {Widget} from './Components/Widget';
import {WeatherWidget} from './Widgets/WeatherWidget';
import one from "./assets/muni/1.jpg";
import two from "./assets/muni/2.jpg";
import three from "./assets/muni/3.jpg";
import four from "./assets/muni/4.jpg";
import five from "./assets/muni/5.jpg";
import six from "./assets/muni/6.jpg";
import seven from "./assets/muni/7.jpg";
import eight from "./assets/muni/8.jpg";
import nine from "./assets/muni/9.jpg";
import ten from "./assets/muni/10.jpg";
import {UpcomingEvents} from './Widgets/UpcomingEvents';
import { BackgroundSlider } from './Components/BackgroundSlider';
import { AdvertisementWidget } from './Widgets/AdvertisementWidget';
import { AuthenticationWidget } from './Widgets/AuthenticationWidget';

export interface RecordsRecent {
    id: string,
    issuer: string,
    time: number,
    name: string,
}
export interface CoffeeRecord {
    id: string,
    time: number,
    user_id: string
}

export interface SocketEvent {
    name: string,
    data: any,
}

const socket = socketIOClient(SOCKET_URL);
const userObs = initSocket('user');
const coffeeObs = initSocket('record');
const authObs = initSocket('authentication_requests')

function initSocket(event: string): Observable<SocketEvent> {
    return new Observable<SocketEvent>(observer => {
        socket.on(event, data => {
            observer.next({name: event, data: data});
        })
    });
}

function Page() {

    const [recordsObserver] = useState(coffeeObs);
    const [userObserver] = useState(userObs);
    const [authObserver] = useState(authObs);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline>
                <Grid container spacing={1} style={{padding: 0}}>
                    <Grid item xs={12} md={4} lg={3} xl={2} style={{backgroundColor: 'navyblue'}}>
                        <Widget type="static">
                            <Clock />
                        </Widget>
                        <Widget type="static">
                            <WeatherWidget />
                        </Widget>
                        <Widget title="Meetings" type="static">
                            <UpcomingEvents />
                        </Widget>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={2}>
                        <Widget title="Last Drinkers">
                            <RecentItems usersObserver={userObserver} recordObserver={recordsObserver} />
                        </Widget>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={4}>
                        <Widget title="Coffee Leaderboard">
                            <HighScore usersObserver={userObserver} recordObserver={recordsObserver} />
                        </Widget>
                    </Grid>
                    <Grid item xs={12} md={2} lg={2} xl={4}>
                        <Widget title="Advertisement">
                            <AdvertisementWidget />
                        </Widget>
                        <Widget>
                            <span>Do you have an idea what information can be displayed here? Contact us at coffee@ics.muni.cz.</span>
                        </Widget>
                    </Grid>
                </Grid>
                <AuthenticationWidget authObserver={authObserver} />
            </CssBaseline>
        </ThemeProvider>
    );
}

export interface User {
    _id: string,
    name: string,
    organization: string
}

function App(){
    const images = [one, two, three, four, five, six, seven, eight, nine, ten];

      return (
        <>
            <BackgroundSlider images={images} duration={600} />
            <Page />
        </>
      )
}

export default App;