import { FC } from "react";
import ReactTimeago from "react-timeago";

type Props = {
	start: Date;
    end: Date;
};

export const EventRecordDate: FC<Props> = ({start, end}) => {

    const now = new Date();
    
    let message: string = "";
    message += start.getHours().toString().padStart(2,'0') + ":" + start.getMinutes().toString().padStart(2,'0')
    message += " - "
    message += end.getHours().toString().padStart(2,'0') + ":" + end.getMinutes().toString().padStart(2,'0')
    message += ", "

    return (
        <span>{message}
        {now < start ? (
            <span style={{ color: '#ffffff59' }}>Starts in <ReactTimeago date={start} /></span>
        ) : ""}
        {now >= start ? (
            <span style={{ color: '#ffffff59' }}>Ends in <ReactTimeago date={end} /></span>
        ) : ""}
        </span>
    )
}