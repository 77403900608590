import { FC, useEffect, useState } from "react";

type Props = {
    images: string[],
    duration: number,
};

export const BackgroundSlider: FC<Props> = ({images, duration}) => {

    useEffect(()=>{
        const interval = setInterval(()=>changeBackgroundImage(), duration*1000)
        return ()=> clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [imgOne, setImgOne] = useState<number>(0);
    const [imgTwo, setImgTwo] = useState<number>(1);

    const changeBackgroundImage = () => {
       setImgOne(prev => (prev+1) % (images.length));
       setImgTwo(prev => (prev+1) % (images.length));
    }

    return (
        <>
            <div id="imgOne" className="background-slider" style={{backgroundImage: 'url(' + images[imgOne] + ')', zIndex: -999}}>
            </div>
            <div id="imgTwo" className="background-slider" style={{backgroundImage: 'url(' + images[imgTwo] + ')', zIndex: -1000}}>
            </div>
        </>
    )
}